import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { AffiliateItemType } from 'js/types/affiliate';
import { PointPattern } from 'js/components/common/Point/PointPattern';
import dayjs from 'dayjs';

export type Props = {
  affiliateItem: Omit<AffiliateItemType, 'createdAt'>;
  endAt: string;
  totalCount: number;
  remainingCount: number;
};

export const LimitedHighBonusPresenter: React.FC<Props> = ({
  affiliateItem,
  endAt,
  totalCount,
  remainingCount,
}) => {
  // サーバとブラウザの標準時間が異なりHydration Errorが発生するため、ブラウザで時刻の計算を行う
  // 可能であればuseEffectを使いたくない
  const [isAccrualLimit, setIsAccrualLimit] = useState(remainingCount === 0);
  const [dayDiff, setDayDiff] = useState('');
  useEffect(() => {
    const startDate = dayjs().locale('ja');
    const endDate = dayjs(endAt).locale('ja');
    const daysDiff = endDate.diff(startDate, 'day');
    const hoursDiff = endDate.diff(startDate.add(daysDiff, 'day'), 'hour');
    const minutesDiff = endDate.diff(
      startDate.add(daysDiff, 'day').add(hoursDiff, 'hour'),
      'minute',
    );
    let dayText = 'あと';
    if (daysDiff > 0) {
      dayText += `${daysDiff}日`;
    }
    if (hoursDiff > 0) {
      dayText += `${hoursDiff}時間`;
    }
    dayText += `${minutesDiff}分`;
    setDayDiff(dayText);

    if (startDate.isAfter(endDate)) {
      setIsAccrualLimit(true);
    }
  }, [endAt]);

  const content = (
    <>
      <img
        className="limited-high-bonus__image"
        src={affiliateItem.imageUrl}
        alt={affiliateItem.itemName}
        width="90"
        height="90"
      />

      <div className="limited-high-bonus__detail">
        <p className="limited-high-bonus__name">{affiliateItem.itemName}</p>
        <p className="limited-high-bonus__condition">
          {affiliateItem.condition}
        </p>

        <PointPattern
          baseClassName="limited-high-bonus"
          regularPoint={affiliateItem.regularPoint}
          campaignPoint={affiliateItem.campaignPoint}
          beforeSize={'medium'}
          afterSize={'xx-large'}
        />

        <p className="limited-high-bonus__remaining-time">
          {isAccrualLimit ? '募集人数に達しました' : dayDiff}
        </p>
      </div>
    </>
  );
  return (
    <>
      <section
        className={classNames(
          'limited-high-bonus',
          {
            'is-accrual-limit': isAccrualLimit,
          },
          'c_section',
        )}
      >
        <div className="limited-high-bonus__wrapper">
          <div className="limited-high-bonus__heading-wrapper">
            <h2 className="limited-high-bonus__heading">
              <span className="limited-high-bonus__heading-number">
                {totalCount}
              </span>
              人限定!
              <br />
              <span className="limited-high-bonus__heading-sub-heading">
                超高還元!
              </span>
            </h2>
            <p className="limited-high-bonus__remaining-number">
              {isAccrualLimit ? (
                <>
                  募集人数に
                  <wbr />
                  達しました
                </>
              ) : (
                <>
                  あと
                  <span className="color-red">
                    <span className="is-emphasis">
                      {remainingCount.toLocaleString()}
                    </span>
                    人
                  </span>
                </>
              )}
            </p>
          </div>

          <div className="limited-high-bonus__detail-wrapper">
            {isAccrualLimit ? (
              <div className="limited-high-bonus__link">{content}</div>
            ) : (
              <a
                href={affiliateItem.linkUrl}
                className="limited-high-bonus__link c_blocklink c_hover-pop c_reset"
              >
                {content}
              </a>
            )}
          </div>
        </div>
      </section>
    </>
  );
};
