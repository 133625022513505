import React from 'react';
import {
  SignUpCampaignPresenter,
  Props as PresenterProps,
} from 'js/components/specific/top/SignUpCampaign/presenter';

export type Props = PresenterProps;

export const SignUpCampaign: React.FC<Props> = (props) => {
  return <SignUpCampaignPresenter {...props} />;
};
