import { Tabs, Props as TabsProps } from 'js/components/ui/Tab/Tabs';
import { TabList, Props as TabListProps } from 'js/components/ui/Tab/TabList';
import { Tab, Props as TabProps } from 'js/components/ui/Tab/Tab';
import {
  TabPanel,
  Props as TabPanelProps,
} from 'js/components/ui/Tab/TabPanel';

export { Tabs, TabList, Tab, TabPanel };
export type { TabsProps, TabListProps, TabProps, TabPanelProps };
