import React from 'react';
import { SignUpCampaignType } from 'js/components/specific/top/SignUpCampaign/type';

export type Props = { signUpCampaign: SignUpCampaignType };

export const SignUpCampaignPresenter: React.FC<Props> = ({
  signUpCampaign,
}) => (
  <a href={signUpCampaign.linkUrl}>
    <img
      src={signUpCampaign.bannerUrl}
      alt="入会キャンペーン"
      className="signup-campaign__img"
    />
  </a>
);
